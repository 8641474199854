import React, { useEffect, useRef } from "react";
import { HomeTitle } from "../../components/Headings";
import { HomeSection } from "../../components/Sections/styles";
import {
  FinalSection,
  TextScrollable,
  VideoContainer,
  VideoContainerRatio,
  VideoSecondSection,
  VideoSection,
  HeadingBanner,
  DoubleColumns,
  BannerButtons,
  BtnLinksSocial,
} from "./styles";
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoWhatsapp,
  IoLogoYoutube,
  IoMdMailOpen,
  IoMdSearch,
} from "react-icons/io";
import { redirectTo } from "./functions";
import { CarouselXls } from "../../components/Carousel";
import ImageViewer from "../../components/ImageViewer";
import { WhatsappWidget } from "../../components/WhatsappWidget";

interface Props {
  toggleTheme?(): void;
}

export const Home: React.FC<Props> = () => {
  const [imageLink, setImageLink] = React.useState("");
  const [visibility, setVisibility] = React.useState("hidden");
  const [visibilityBlackFriday, setVisibilityBlackFriday] =
    React.useState("hidden");
  const video1 = "https://www.youtube.com/watch?v=hTchl7_AO6k";
  const videoAtendimento = "https://www.youtube.com/watch?v=P1-dCqXZcrE";
  const blackFridayImg = "/images/black-friday.jpeg?20240515";
  const blackFridayLink =
    "https://wa.me/5511980982793?text=Olá, tenho interesse na promoção dos cursos de Maio com 50% Off!";
  const timestampBannerLimit = 1717199999000;
  const now = useRef(new Date().getTime());

  const videoEmbed = (
    link: string = "https://www.youtube.com/watch?v=P1-dCqXZcrE"
  ) => {
    const video = link.split("watch?v=");
    return `https://www.youtube-nocookie.com/embed/${video[1]}?rel=0&controls=0`;
  };

  useEffect(() => {
    if (now.current < timestampBannerLimit) {
      setVisibilityBlackFriday("visible");
    }
  }, [now]);

  return (
    <>
      <ImageViewer
        imageLink={imageLink}
        visibility={visibility}
        setVisibility={setVisibility}
      />
      <ImageViewer
        imageLink={blackFridayImg}
        visibility={visibilityBlackFriday}
        setVisibility={setVisibilityBlackFriday}
        externalLink={blackFridayLink}
      />
      <WhatsappWidget />
      <HeadingBanner id="home">
        <img src="/images/uniholi_capa.png" alt="Banner Terapia da Chris" />
        <BannerButtons>
          <div>
            <a
              href="https://wa.me/5511980982793"
              rel="noreferrer"
              target="_blank"
            >
              <IoLogoWhatsapp style={{ color: "green", fontSize: 32 }} />
              &nbsp;<span>11 - 98098-2793</span>
            </a>
            <a
              href="https://www.youtube.com/@terapiadachris"
              rel="noreferrer"
              target="_blank"
            >
              <IoLogoYoutube style={{ color: "red", fontSize: 40 }} />
              &nbsp;<span>@terapiadachris</span>
            </a>
          </div>
        </BannerButtons>
      </HeadingBanner>
      <HomeSection>
        <VideoSection>
          <VideoContainer>
            <iframe
              src={videoEmbed(video1)}
              title="YouTube video player"
            ></iframe>
          </VideoContainer>
          <TextScrollable>
            <img
              src="/images/pergaminho.png"
              alt="Aprimorando o Espírito"
              onClick={() => {
                setImageLink("/images/pergaminho.png");
                setVisibility("visible");
              }}
            />
          </TextScrollable>

          {/* <TextScrollable>
            <h1>Aprimorando o Espírito</h1>
            <div>
              <p>
                &nbsp;&nbsp;&nbsp;A premente necessidade em unir razão e
                coração, intelecto e espírito, enseja a oportunidade de recorrer
                à autoridade das entidades que habitam esta esfera, para que se
                manifestem em auxílio à iluminação do plano terrestre, o que
                somente ocorrerá mediante mudança nas crenças e no medo de
                conduzir a existência, através do reequilíbrio energético e da
                busca por caminhos de superação.
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;Neste contexto, e sem qualquer conotação
                religiosa ou doutrinária, apenas almejando quebrar paradigmas e
                contribuir para o despertar humano em busca da harmonia com a
                consciência universal, surge a Universidade Holística.
              </p>
              <p className="citation">
                &nbsp;&nbsp;&nbsp;"Amados discípulos, Sempre que vos desligardes
                dos confusos e conturbados problemas gerados pela vontade
                humana, esta vontade a que dais tanta atenção e valor, e
                fixardes vossa consciência na Consciência Eu Sou, abrir-se-á o
                canal de ligação entre vós e as Legiões de Luz e será possível
                enviar-vos torrentes corpos e auxiliando-vos na solução de
                vossos problemas. <br />
                &nbsp;&nbsp;&nbsp;Amor e Bênção."
              </p>
              <p className="citation-owner">
                {" "}
                - El Morya (O Guardião da Vontade de Deus!)
              </p>
            </div>
          </TextScrollable> */}
        </VideoSection>
      </HomeSection>
      <HomeSection>
        <FinalSection>
          <HomeTitle>Nossos cursos pagos:</HomeTitle>
          <CarouselXls
            setImageLink={setImageLink}
            setVisibility={setVisibility}
            curso_xls="/tabela_de_preços_cursos_pagos.xlsx"
          />
        </FinalSection>
      </HomeSection>
      <HomeSection>
        <FinalSection>
          <HomeTitle>Nossos cursos gratuitos:</HomeTitle>
          <CarouselXls
            setImageLink={setImageLink}
            setVisibility={setVisibility}
            curso_xls="/tabela_de_preços_cursos_gratis.xlsx"
          />
          {/* <Carousel setImageLink={setImageLink} setVisibility={setVisibility} /> */}
        </FinalSection>
      </HomeSection>
      <HomeSection id="atendimento">
        <VideoSecondSection>
          <HomeTitle>Receba um atendimento gratuito!</HomeTitle>
          <VideoContainerRatio>
            <iframe
              src={videoEmbed(videoAtendimento)}
              title="YouTube video player"
            ></iframe>
          </VideoContainerRatio>
        </VideoSecondSection>
      </HomeSection>
      <HomeSection id="novidades">
        <FinalSection>
          <HomeTitle>Promoções do Mês de Novembro</HomeTitle>
          <DoubleColumns>
            <div className="singleColumn">
              <a
                href="https://wa.me/5511980982793?text=Olá, tenho interesse nas promoções da Black Friday"
                target={"_blank"}
                rel="noreferrer"
              >
                <img
                  src="/images/black-friday.jpeg?20241111"
                  alt="Promoções da Black Friday, descontos de até 50%"
                />
              </a>
            </div>
          </DoubleColumns>
        </FinalSection>
      </HomeSection>
      <HomeSection id="contato">
        <FinalSection>
          <div style={{ width: "100%" }}>
            <HomeTitle>Siga-nos nas redes e entre em contato conosco</HomeTitle>
          </div>
          <DoubleColumns>
            <div className="column">
              <img src="/images/chris.png" alt="Mestra Chris Yanovich" />
            </div>
            <div className="column buttons">
              <BtnLinksSocial
                onClick={() =>
                  redirectTo(
                    "https://www.instagram.com/universidade_holistica/"
                  )
                }
              >
                <div className="iconSearch">
                  <IoMdSearch style={{ fontSize: 40, color: "white" }} />
                </div>
                <div>
                  <IoLogoInstagram style={{ fontSize: 40 }} />
                  <span>Instagram</span>
                </div>
              </BtnLinksSocial>
              <BtnLinksSocial
                onClick={() =>
                  redirectTo("https://www.facebook.com/uniholi.terapiadachris")
                }
              >
                <div className="iconSearch">
                  <IoMdSearch style={{ fontSize: 40, color: "white" }} />
                </div>
                <div>
                  <IoLogoFacebook style={{ fontSize: 40 }} />
                  <span>Facebook</span>
                </div>
              </BtnLinksSocial>
              <BtnLinksSocial
                onClick={() =>
                  redirectTo("https://www.youtube.com/@terapiadachris")
                }
              >
                <div className="iconSearch">
                  <IoMdSearch style={{ fontSize: 40, color: "white" }} />
                </div>
                <div>
                  <IoLogoYoutube style={{ fontSize: 40 }} />
                  <span>YouTube</span>
                </div>
              </BtnLinksSocial>
              <BtnLinksSocial
                onClick={() =>
                  redirectTo(
                    "https://wa.me/5511980982793?text=Olá, tenho interesse nos cursos da UniHoli!"
                  )
                }
              >
                <div className="iconSearch">
                  <IoMdSearch style={{ fontSize: 40, color: "white" }} />
                </div>
                <div>
                  <IoLogoWhatsapp style={{ fontSize: 40 }} />
                  <span>11 98098-2793</span>
                </div>
              </BtnLinksSocial>
              <BtnLinksSocial
                onClick={() =>
                  redirectTo(
                    "mailto:contato@uniholi.com?subject=Contato UniHoli"
                  )
                }
              >
                <div className="iconSearch">
                  <IoMdSearch style={{ fontSize: 40, color: "white" }} />
                </div>
                <div>
                  <IoMdMailOpen style={{ fontSize: 40 }} />
                  <span>Email</span>
                </div>
              </BtnLinksSocial>
            </div>
          </DoubleColumns>
        </FinalSection>
      </HomeSection>
    </>
  );
};
